import Highway from "@dogstudio/highway";
import Tween from "gsap";

var TOUCH = "ontouchstart" in window;

function initSlider() {
	var $carousel = jQuery(".carousel:not(.flickity-enabled)");
	if ($carousel.length) {
		var $next = $carousel.next(".carousel-nav").find(".carousel-nav-next");
		var $prev = $carousel.next(".carousel-nav").find(".carousel-nav-prev");
		var $count = jQuery(".carousel-count");
		var flickity;
		flickity = new Flickity(".carousel", {
			draggable: TOUCH,
			freeScroll: false,
			wrapAround: true,
			prevNextButtons: false,
			pageDots: false,
			fade: true,
			lazyLoad: 1,
			adaptiveHeight: false,
			imagesLoaded: true,
			cellSelector: ".carousel-image",
			setGallerySize: false,
			contain: false,
			cellAlign: "right",
			selectedAttraction: 0.01,
			friction: 0.15,
			on: {
				ready: function() {
					jQuery(".carousel").focus();
					checkSlideVideos(1);
				},
				change: function(index) {
					updateSlideCount(index);
					checkSlideVideos(+index + 1);
				}
			}
		});
		$next.on("click", function() {
			flickity.next();
		});
		$prev.on("click", function() {
			flickity.previous();
		});

		// Click + swipe on mobile
		if (TOUCH) {
			jQuery(".carousel").on("staticClick.flickity", function(
				event,
				pointer,
				cellElement,
				cellIndex
			) {
				var px = parseInt(pointer.pageX),
					ww = window.innerWidth;
				if (px <= ww / 2) {
					// Prev
					flickity.previous();
				} else {
					// Next
					flickity.next();
				}
			});
		}

		// Counter
		function updateSlideCount(index) {
			if ($count.length) {
				var cellNumber = index + 1;
				$count.html(cellNumber + "/" + flickity.slides.length);
			}
		}

		// Video playback
		function checkSlideVideos(index) {
			jQuery(".carousel-image:not(.is-selected) video").each(function() {
				var $dis = jQuery(this);
				$dis.get(0).pause();
			});
			jQuery(".carousel-image.is-selected video").each(function() {
				var $dis = jQuery(this);
				if (this.paused) {
					this.play();
				}
			});

			// Time update tracking
			jQuery(".carousel-image video:not(.is-tracked)").each(function() {
				var $dis = jQuery(this);
				var dis = this;
				$dis.addClass("is-tracked");
				dis.addEventListener(
					"timeupdate",
					function() {
						jQuery(".button-progress span.progress").html(
							calcVideoProgress(dis.currentTime)
						);
						jQuery(".button-progress span.remaining").html(
							calcVideoLeft(dis.currentTime, dis.duration)
						);
					},
					false
				);
			});

			// Toggle mute button
			if (jQuery(".carousel-video.is-selected").length) {
				jQuery(".button-mute, .button-progress").addClass("is-active");
			} else {
				jQuery(".button-mute, .button-progress").removeClass(
					"is-active"
				);
			}
		}

		updateSlideCount(0);
	}
}

// Video time calculations
function calcVideoProgress(time) {
	var s = parseInt(time % 60);
	var m = parseInt((time / 60) % 60);
	if (s < 10) {
		s = "0" + s;
	}
	if (m < 10) {
		m = "0" + m;
	}
	return m + ":" + s;
}
function calcVideoLeft(time, duration) {
	var s = parseInt((duration - time) % 60);
	var m = parseInt(((duration - time) / 60) % 60);
	if (s < 10) {
		s = "0" + s;
	}
	if (m < 10) {
		m = "0" + m;
	}
	return m + ":" + s;
}

// Fade
class Fade extends Highway.Transition {
	in({ from, to, done }) {
		// Remove Old View
		from.remove();
		setTimeout(function() {
			initSlider();
		}, 250);
		// Animate
		Tween.fromTo(
			to,
			0.75,
			{ opacity: 0 },
			{
				opacity: 1,
				onComplete: done
			}
		);
	}

	out({ from, done }) {
		// Animation
		Tween.fromTo(
			from,
			0.75,
			{ opacity: 1 },
			{
				opacity: 0,
				onComplete: done
			}
		);
	}
}

export default Fade;
