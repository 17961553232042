import Highway from "@dogstudio/highway";
// Import Transitions
import Fade from "nav/fade.js";

// Call Highway.Core once.
const H = new Highway.Core({
	transitions: {
		default: Fade
	}
});

// Listen Events
window.onload = function() {
	H.on("NAVIGATE_IN", ({ to }) => {
		quicklink.listen();
		var bgChange = to.view;
		var bgC = jQuery(bgChange).attr("data-bg")
			? jQuery(bgChange).attr("data-bg")
			: "#fdfdfb";
		jQuery("body, #main").css("background-color", bgC);
	});
};
